import React, { FC, useEffect, useState } from "react";
import { FiAlertCircle, FiCheckCircle, FiLoader } from "react-icons/fi";

import { RouteComponentProps } from "@reach/router";

import { verifyAccount } from "../api/me";
import { PublicRoute } from "../components/PublicRoute";
import { SEO } from "../components/Seo";
import { H4 } from "../components/Typography";
import { Paths } from "../paths";

enum Verification {
  Loading,
  Success,
  Error,
}

const VerifyAccount: FC<RouteComponentProps> = (props) => {
  const [verification, setVerification] = useState(Verification.Loading);
  let token: string | null = null;

  if (props.location && typeof window !== "undefined") {
    const params = new URLSearchParams(props.location.search);
    token = params.get("token");
  }

  function renderMessage(): JSX.Element {
    switch (verification) {
      case Verification.Success:
        return (
          <div className="flex flex-col items-center justify-center">
            <FiCheckCircle size={56} color="#1032ac" />
            <H4 className="text-center mt-4">
              Your email is now verified.
              <br />
              You will be redirected to the sign in page in seconds.
            </H4>
          </div>
        );
      case Verification.Error:
        return (
          <div className="flex flex-col items-center justify-center">
            <FiAlertCircle size={56} color="#eb5757" />
            <H4 className="text-center mt-4">
              We could not verify your email right now.
              <br />
              Please try again later.
            </H4>
          </div>
        );
      default:
        return (
          <div className="flex flex-col items-center justify-center">
            <FiLoader size={56} color="#b3b3b3" />
            <H4 className="text-center mt-4">Verifying your account...</H4>
          </div>
        );
    }
  }

  useEffect(() => {
    async function verify() {
      if (!token) {
        setVerification(Verification.Error);
        return;
      }

      try {
        await verifyAccount(token as string);
        setVerification(Verification.Success);
        const t = setTimeout(() => {
          clearTimeout(t);
          props.navigate?.(Paths.SignIn);
        }, 5000);
      } catch (err) {
        setVerification(Verification.Error);
      }
    }

    verify();
  }, []);

  return (
    <PublicRoute {...props}>
      <SEO title="Activate account" />
      <div className="flex min-h-full flex-col mt-auto mb-0 sm:-mb-20 px-4 lg:flex-row lg:px-0">
        <div className="w-full flex justify-center px-4">
          <div className="flex flex-col flex-1 bg-white w-full max-w-xl p-16">{renderMessage()}</div>
        </div>
      </div>
    </PublicRoute>
  );
};

export default VerifyAccount;
